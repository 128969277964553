import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Grid, Paper, makeStyles, Typography, TextField } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router';
// import L from 'leaflet';
import MomentUtils from '@date-io/moment';
import { DatePicker } from '@material-ui/pickers';

import { WebContext } from 'src/scenes/web-context';
import { useProjectApi, useGeoInfoApi } from 'src/services';
import { TitleContainer, ContentContainer, MapWrapper, ChartWrapper, DialogWrap, ActionsBox, AccessControl,
  AutocompleteWrapper, DialogExternalImage } from 'src/components';
import { upperCaseOnlyFirstLetter as ucofl } from 'src/utils/formatters';
import { useFetchStatus } from 'src/hooks';
// import { getMarkerIcon } from 'src/components/map/mapHelper';
import { removeUndefinedPropsFromObject as rupfo, removeDuplicatedFromArray as rdfa,
  transparentize, getRandomNviroColors, createForm, getRandomNviroColor } from 'src/utils/util';
import { AnalyticCard, ListIndicator, NumberIndicator, PieChartSkeleton, LineChartSkeleton, SpeciesTypeFilter, EeccList }
  from 'src/scenes/Project/scenes/Summary/components';
import { Skeleton } from '@material-ui/lab';

import { buildLeafletLayer, getLegend, buildLeafletLayerFromTile } from 'src/scenes/Project/scenes/Analytics/smaAnalyticsHelper';
import { SmaMapCreateDialog, SmaGeoInfo, ProcessGeoInfoDialog }
  from 'src/scenes/Project/scenes/Analytics/components';
import { AddBox } from '@material-ui/icons';


const { moment } = new MomentUtils();

// const createMarker = ({ auditableUnit, index, selectedAuditableUnitId, setSelectedAuditableUnitId }) => {
//   const coords = { lat: auditableUnit.latitude, lng: auditableUnit.longitude };
//   if (coords) {
//     const isSelected = selectedAuditableUnitId === auditableUnit.id;
//     const icon = isSelected ? getMarkerIcon(`U${index + 1}`, 'calypso') : getMarkerIcon(`U${index + 1}`, 'nviro');
//     const marker = new L.Marker(coords, { icon });
//     marker.metaData = auditableUnit;
//     marker.on('click', () => {
//       setSelectedAuditableUnitId(auditableUnit.id);
//     });
//     return marker;
//   } else {
//     return undefined;
//   }
// };

// const createAuditableUnitLayer = ({ auditableUnits, selectedAuditableUnitId, setSelectedAuditableUnitId }) => {
//   const layer = new L.FeatureGroup();
//   const markers = auditableUnits.filter(au => au.latitude && au.longitude).map((auditableUnit, index) => createMarker({
//     auditableUnit,
//     index,
//     selectedAuditableUnitId,
//     setSelectedAuditableUnitId,
//   })).filter(rs => rs);

//   markers.forEach(marker => layer.addLayer(marker));

//   return layer;
// };

// const createReplicaStationsLayer = replicaStations => {
//   const layer = new L.FeatureGroup();
//   replicaStations.forEach(rs => {
//     const coords = { lat: rs.latitude, lng: rs.longitude };
//     const marker = new L.CircleMarker(coords, { radius: 6, fillOpacity: 0.2, weight: 2, color: '#5BD6E6' });
//     return layer.addLayer(marker);
//   });
//   return layer;
// };

// const heightVH = n => document.documentElement.clientHeight * (n / 100);

const mapOptions = {
  center: [ -32.48431324745671, -71.25296320202341 ],
  zoomControl: true,
  dragging: true,
  zoom: 16,
  doubleClickZoom: false,
  attributionControl: false,
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: ({ variant }) => (
      variant === 'light' ? theme.palette.common.gray600
      : variant === 'dark' ? theme.palette.common.lightGray
      : theme.palette.common.gray600
    ),
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  map: {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  paper: {
    padding: theme.spacing(2),
  },
  number: {
    width: ({ isLoading }) => isLoading ? '40%' : '100%',
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 600,
    color: theme.palette.common.black,
  },

  auditableUnitLabel: {
    backgroundColor: '#F7931E !important',
  },
  itemRootBorder: {
    borderRight: `1px solid ${theme.palette.common.gray}`,
  },
}));

const getSelectedAuditableUnit = (auditableUnits, selectedAuditableUnitId) => {
  const selectedAuditableUnit = auditableUnits.find(au => au.id === selectedAuditableUnitId);
  return selectedAuditableUnit ? [ selectedAuditableUnit ] : auditableUnits;
};

const emptyChartData = {
  labels: [],
  datasets: [],
};

const speciesColors = getRandomNviroColors(5);
const buildSpeciesChart = ({ chartData, prefix }) => {
  const colors = speciesColors;
  const filterKeys = [ 'Occurrences', 'Species', 'EeccSpecies', 'NativeSpecies', 'EndemicSpecies' ]
    .map(f => `${prefix}${f}`);
  const chart = {
    labels: chartData.years,
    datasets: Object.keys(chartData).filter(key => filterKeys.includes(key)).map((key, idx) => (
      {
        data: chartData[key].data,
        label: chartData[key].label,
        backgroundColor: transparentize(colors[idx % 5]),
        borderColor: colors[idx % 5],
        tension: 0.1,
      }
    )),
    originData: chartData,
  };
  return chart;
};

const buildSpeciesIndicator = ({ indicatorsData, prefix }) =>
  ({
    occurrences: indicatorsData[`${prefix}Occurrences`],
    species: indicatorsData[`${prefix}Species`],
    eecc: indicatorsData[`${prefix}EeccSpecies`],
    natives: indicatorsData[`${prefix}NativeSpecies`],
    endemic: indicatorsData[`${prefix}EndemicSpecies`],
    lastYear: indicatorsData.lastYear,
    originData: indicatorsData,
  });

const buildSpeciesAllocationChart = info => {
  const colors = getRandomNviroColors();
  const { labels, data, backgroundColor } = info.reduce((obj, curr, index) => {
    obj.labels.push(curr.name);
    obj.data.push(curr.value);
    obj.backgroundColor.push(transparentize(colors[index % 7], 0.1));
    return obj;
  }, { labels: [], data: [], backgroundColor: [] });

  return {
    labels,
    datasets: [ {
      data,
      backgroundColor,
      borderColor: 'rgba(64, 71, 75, 1)',
      borderWidth: 1,
    } ],
  };
};

const SmaSummaryContainer = props => {

  const match = useRouteMatch();
  const history = useHistory();
  const projectApi = useProjectApi();
  const geoInfoApi = useGeoInfoApi();
  const webContext = useContext(WebContext);

  const minDate = useMemo(() => new Date(2017, 0, 1), []);
  const maxDate = useMemo(() => {
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() - 2);
    return maxDate;
  }, []);

  const { projectId } = match.params;


  const [ selectedAuditableUnitId ] = useState(undefined);

  const [ indicators, setIndicators ] = useState({});
  const [ speciesIndicators, setSpeciesIndicators ] = useState({});
  const [ speciesTypeFilter, setSpeciesTypeFilter ] = useState('total');
  const [ speciesByYearTypeFilter, setSpeciesByYearTypeFilter ] = useState('total');
  const [ eeccTypeFilter, setEeccTypeFilter ] = useState('flora');
  const [ speciesChart, setSpeciesChart ] = useState(emptyChartData);
  const [ speciesAllocationCharts, setSpeciesAllocationCharts ] = useState({
    kingdom: emptyChartData,
    flora: emptyChartData,
    fauna: emptyChartData,
  });
  const [ holders, setHolders ] = useState([]);
  const [ rcas, setRcas ] = useState([]);
  const [ auditableUnits, setAuditableUnits ] = useState([]);
  const [ eeccs, setEeccs ] = useState({});
  const [ mapsGeoTiles, setMapsGeoTiles ] = useState({ leftDate: minDate, rightDate: maxDate, minDate, maxDate });

  const [ tileData, setTileData ] = useState({ data: undefined, hasData: false, inProgress: false });
  const [ tileOptions, setTileOptions ] = useState([ { label: '', value: '' } ]);
  const [ tilesComp, setTilesComp ] = useState({});
  // const [ layers, setLayers ] = useState([]);

  const [ analyticMap, setAnalyticMap ] = useState({ layers: [], occurrencesLayer: {},
    displayOnMap: { tileData: false }, hasSatInfo: false });
  const [ geoInfo, setGeoInfo ] = useState({ data: undefined, hasData: false, inProgress: false });


  const { fetchStatus, setFetchStatus, fetchStatusEnum, isLoading } = useFetchStatus();

  const classes = useStyles({ isLoading });

  const { LOADING, SUCCESS } = fetchStatusEnum;

  useEffect(() => {
    const fetchData = async () => {
      setFetchStatus(LOADING);

      const [ generalInfo, { indicatorsData, chartData }, speciesAllocationInfo, auditableUnits, rcas, eeccOccurrences,
        analyticsMapsData, geoInfo, tileData, tileOptions ] = await Promise
        .all([
          projectApi.getGeneralInfo(rupfo({ projectId, auditableUnitId: selectedAuditableUnitId })),
          projectApi.getSpeciesInfo(rupfo({ projectId, auditableUnitId: selectedAuditableUnitId })),
          projectApi.getSpeciesAllocationInfo(rupfo({ projectId, auditableUnitId: selectedAuditableUnitId })),
          projectApi.getLinkedAuditableUnits(projectId),
          projectApi.rcaSearch({ projectId, params: { auditableUnitId: selectedAuditableUnitId, limit: 0 } }),
          projectApi.getEeccOccurrences(rupfo({ projectId, auditableUnitId: selectedAuditableUnitId })),

          projectApi.getAnalyticsMaps({ projectId }),
          projectApi.getGeoInfo({ projectId }),
          projectApi.getTileData({ projectId }),
          geoInfoApi.getTileOptions(),
        ]);

      const layers = analyticsMapsData.layers.map(amd => buildLeafletLayer({
        features: amd.geojson,
        id: amd.id,
        name: amd.name,
        color: getRandomNviroColor(),
      }));
      const tilesComp = {
        hasData: tileData.hasData,
      };

      setTileOptions(tileOptions);

      if (tileData.hasData) {
        tilesComp['display'] = true;
        const selectedTileData = tileOptions.find(tile => tile.value === geoInfo.data.mapConfig.tile);
        setMapsGeoTiles({
          leftDate: geoInfo.data.mapConfig.leftDate,
          rightDate: geoInfo.data.mapConfig.rightDate,
          tile: selectedTileData,
          minDate: selectedTileData.minDate ? new Date(selectedTileData.minDate) : minDate,
          maxDate: selectedTileData.maxDate ? new Date(selectedTileData.maxDate) : maxDate,
        });

        tilesComp['left'] = {
          satelital: buildLeafletLayerFromTile(tileData.tilesUrl.left.satelital),
          ...(tileData.tilesUrl.left.indicator ? { indicator: buildLeafletLayerFromTile(tileData.tilesUrl.left.indicator) } : {}),
        };
        tilesComp['right'] = {
          satelital: buildLeafletLayerFromTile(tileData.tilesUrl.right.satelital),
          ...(tileData.tilesUrl.right.indicator ? { indicator: buildLeafletLayerFromTile(tileData.tilesUrl.right.indicator) } : {}),
        };
      }
      setTilesComp(tilesComp);

      const timestampDates = analyticsMapsData.allDates.map(({ date }) => moment(date, 'DD/MM/YYYY').valueOf());
      const deletePermission = 'project:delete-interest-area';
      const { permissions } = webContext;

      setAnalyticMap({
        layers,
        occurrencesLayer: null,
        hasSatInfo: tileData.hasData,
        dates: timestampDates,
        displayOnMap: { tileData: tilesComp.display },
        deletePermission: permissions?.includes(deletePermission),
        legend: getLegend({ deleteInterestArea, layers, hasOccurrencesLayer: analyticsMapsData.occurrences.length > 0,
          hideTileLayer, displayOnMap: { tileData: tilesComp.display },
          deletePermission: permissions?.includes(deletePermission),
          hasSatInfo: tileData.hasData,
        }),
      });
      setGeoInfo(geoInfo);


      if (generalInfo) {
        setIndicators({
          monitoringCount: generalInfo.nMonitoringPlans,
          ocurrencesCount: generalInfo.nOccurrences,
          campaignsCount: generalInfo.nCampaigns,
          replicaStationsCount: generalInfo.nStationReplicate,
          speciesCount: generalInfo.nSpecies,
          eeccCount: 0,
          nativeExoticCount: 0,
          endemicCount: 0,
        });
      }

      if (indicatorsData) {
        const speciesIndicators = buildSpeciesIndicator({ indicatorsData, prefix: speciesTypeFilter });
        setSpeciesIndicators(speciesIndicators);
      }

      if (chartData) {
        const chart = buildSpeciesChart({ chartData, prefix: speciesTypeFilter });
        setSpeciesChart(chart);
      }

      if (speciesAllocationInfo) {
        const kingdom = buildSpeciesAllocationChart(speciesAllocationInfo.kingdomData);
        const flora = buildSpeciesAllocationChart(speciesAllocationInfo.floraData);
        const fauna = buildSpeciesAllocationChart(speciesAllocationInfo.faunaData);
        setSpeciesAllocationCharts({ kingdom, flora, fauna });
      }
      setEeccs(eeccOccurrences);
      setRcas(rcas.map(r => r.id));
      setHolders(rdfa(getSelectedAuditableUnit(auditableUnits, selectedAuditableUnitId).map(au => au.titleHolder)));
      setAuditableUnits(auditableUnits);
      setFetchStatus(SUCCESS);
    };

    if (webContext.permissions) {
      fetchData();
    }
    //eslint-disable-next-line
  }, [ selectedAuditableUnitId, webContext.permissions ]);

  useEffect(() => {
    const { originData: indicatorsData } = speciesIndicators;
    if (indicatorsData) {
      const speciesIndicators = buildSpeciesIndicator({ indicatorsData, prefix: speciesTypeFilter });
      setSpeciesIndicators(speciesIndicators);
    }

    //eslint-disable-next-line
  }, [ speciesTypeFilter ]);

  useEffect(() => {
    const { originData: chartData } = speciesChart;
    if (chartData) {
      const chart = buildSpeciesChart({ chartData, prefix: speciesByYearTypeFilter });
      setSpeciesChart(chart);
    }
    //eslint-disable-next-line
  }, [ speciesByYearTypeFilter ]);

  const goToCampaigns = () => history.push('campaigns');
  const goToMonitoringPlans = () => history.push('monitoring-plans');

  // const auditableUnitsLayers = createAuditableUnitLayer({ auditableUnits, selectedAuditableUnitId, setSelectedAuditableUnitId });
  // const selectedAuditableUnit = selectedAuditableUnitId ? getSelectedAuditableUnit(auditableUnits, selectedAuditableUnitId)[0]
  //  : undefined;
  // useEffect(() => {
  //   if (selectedAuditableUnitId) {
  //     const replicaStationsLayer = createReplicaStationsLayer(selectedAuditableUnit.stationReplicate);
  //     const bounds = replicaStationsLayer.getBounds();
  //     const auditableUnitBounds = new L.LatLngBounds([ { lat: selectedAuditableUnit.latitude, lng: selectedAuditableUnit.longitude } ]);
  //     bounds.extend(auditableUnitBounds);
  //     map.fitBounds(bounds);
  //     setLayers([ auditableUnitsLayers, replicaStationsLayer ]);
  //   } else if (auditableUnitsLayers.getLayers().length) {
  //     const bounds = auditableUnitsLayers.getBounds();
  //     map.fitBounds(bounds);
  //     setLayers([ auditableUnitsLayers ]);
  //   }
  //   //eslint-disable-next-line
  // }, [ selectedAuditableUnitId, auditableUnits.length ]);

  const deleteInterestArea = async areaOfInterestKmlId => {
    await projectApi.deleteInterestArea({ projectId, areaOfInterestKmlId });
    setAnalyticMap(prev => {
      prev.legend.remove();
      const layers = prev.layers.filter(layer => layer.options.id !== areaOfInterestKmlId);
      return ({
        ...prev,
        layers,
        hasSatInfo: false,
        legend: getLegend({ deleteInterestArea, layers, hasOccurrencesLayer: Boolean(prev.occurrencesLayer),
          hideTileLayer, displayOnMap: prev.displayOnMap,
          deletePermission: prev.deletePermission, hasSatInfo: false }),
      });
    });
    setGeoInfo(prev => ({ ...prev, hasData: false }));
    setTilesComp({ hasData: false });
  };

  const hideTileLayer = () => {
    setTilesComp(prev => ({ ...prev, display: !prev.display }));
    setAnalyticMap(prev => {
      prev.legend.remove();
      return ({
        ...prev,
        displayOnMap: { tileData: !prev.displayOnMap.tileData },
        legend: getLegend({ deleteInterestArea, layers: prev.layers, hasOccurrencesLayer: Boolean(prev.occurrencesLayer),
          hideTileLayer, displayOnMap: { tileData: !prev.displayOnMap.tileData },
          deletePermission: prev.deletePermission, hasSatInfo: prev.hasSatInfo }),
      });
    });
  };

  const saveAnalyticMap = async interestArea => {
    const form = createForm(interestArea.config);
    form.append('kmlFile', interestArea.kmlData.kmlFile, interestArea.kmlData.fileName);
    const { status, areaOfInterestKmlId } = await projectApi.importInterestArea({ projectId, interestArea: form });

    const leafletLayer = buildLeafletLayer({
      features: [ interestArea.layer.geojson ],
      id: areaOfInterestKmlId,
      name: interestArea.layer.name,
    });

    setAnalyticMap(prev => {
      const layers = status === 'created' ?
        [ leafletLayer, ...analyticMap.layers ]
        : prev.layers.map(layer => layer.options.id === areaOfInterestKmlId ? leafletLayer : layer);
      prev.legend.remove();
      return ({
        ...prev,
        layers,
        legend: getLegend({ deleteInterestArea, layers, hasOccurrencesLayer: Boolean(prev.occurrencesLayer),
          hideTileLayer, displayOnMap: prev.displayOnMap, deletePermission: prev.deletePermission, hasSatInfo: prev.hasSatInfo }),
      });
    });
  };

  const [ dialog, setDialog ] = useState({ isOpen: false, data: {}, type: '' });

  const closeDialog = () => setDialog({ isOpen: false, type: '' });
  const openCreateMapDialog = () => setDialog({ isOpen: true, type: 'create-map' });

  const processInterestArea = async() => {
    await projectApi.processInterestArea({ projectId });
    setGeoInfo(prev => ({ ...prev, hasData: true, inProgress: true }));
    setDialog({ isOpen: true, type: 'geo-info' });
    setTilesComp({});
  };

  const updateTileConfig = async mapsTilesConfig => {
    if (geoConfigIsValid(mapsTilesConfig)) {
      setTileData(prev => ({ ...prev, inProgress: true }));
      const mapConfig = {
        leftDate: mapsTilesConfig.leftDate,
        rightDate: mapsTilesConfig.rightDate,
        tile: mapsTilesConfig.tile.value,
      };
      await geoInfoApi.saveMapConfig({ geoDataId: geoInfo.data.id, mapConfig });
      const [ newGeoInfo, tiles ] = await Promise.all([
        projectApi.getGeoInfo({ projectId }),
        projectApi.getTileData({ projectId }),
      ]);
      setGeoInfo(newGeoInfo);
      const tilesComp = { hasData: tileData.hasData };
      if (tiles.hasData) {
        tilesComp['display'] = true;
        tilesComp['left'] = {
          satelital: buildLeafletLayerFromTile(tiles.tilesUrl.left.satelital),
          ...(tiles.tilesUrl.left.indicator ? { indicator: buildLeafletLayerFromTile(tiles.tilesUrl.left.indicator) } : {}),
        };
        tilesComp['right'] = {
          satelital: buildLeafletLayerFromTile(tiles.tilesUrl.right.satelital),
          ...(tiles.tilesUrl.right.indicator ? { indicator: buildLeafletLayerFromTile(tiles.tilesUrl.right.indicator) } : {}),
        };

        setAnalyticMap(prev => {
          prev.legend.remove();
          return ({
            ...prev,
            displayOnMap: { tileData: true },
            hasSatInfo: true,
            legend: getLegend({ deleteInterestArea, layers: prev.layers, hasOccurrencesLayer: Boolean(prev.occurrencesLayer),
              hideTileLayer, displayOnMap: { tileData: true },
              deletePermission: prev.deletePermission, hasSatInfo: true }),
          });
        });
      }
      setTilesComp(tilesComp);
      setTileData(prev => ({ ...prev, inProgress: false }));
    }
  };

  const handleChange = fieldKey => async (_, selectedValue) => {
    const selectedTileData = tileOptions.find(tile => tile.value === selectedValue.value);
    const newMapsGeoTiles = {
      ...mapsGeoTiles,
      minDate: selectedTileData.minDate ? new Date(selectedTileData.minDate) : mapsGeoTiles.minDate,
      maxDate: selectedTileData.maxDate ? new Date(selectedTileData.maxDate) : mapsGeoTiles.maxDate,
    };
    newMapsGeoTiles[fieldKey] = selectedValue;

    if (newMapsGeoTiles.leftDate < newMapsGeoTiles.minDate) {
      newMapsGeoTiles.leftDate = newMapsGeoTiles.minDate;
    } else if (newMapsGeoTiles.maxDate < newMapsGeoTiles.leftDate) {
      newMapsGeoTiles.leftDate = newMapsGeoTiles.maxDate;
    }

    if (newMapsGeoTiles.rightDate < newMapsGeoTiles.minDate) {
      newMapsGeoTiles.rightDate = newMapsGeoTiles.minDate;
    } else if (newMapsGeoTiles.maxDate < newMapsGeoTiles.rightDate) {
      newMapsGeoTiles.rightDate = newMapsGeoTiles.maxDate;
    }

    setMapsGeoTiles(newMapsGeoTiles);
    await updateTileConfig(newMapsGeoTiles);
  };

  const geoConfigIsValid = mapsGeoTiles =>
    mapsGeoTiles.leftDate && mapsGeoTiles.rightDate && mapsGeoTiles.tile;

  const changeTime = fieldKey => async time => {
    const newMapsGeoTiles = { ...mapsGeoTiles };
    if (mapsGeoTiles.minDate <= time && time <= mapsGeoTiles.maxDate) {
      newMapsGeoTiles[fieldKey] = time;
    } else if (time < mapsGeoTiles.minDate) {
      newMapsGeoTiles[fieldKey] = mapsGeoTiles.minDate;
    } else {
      newMapsGeoTiles[fieldKey] = mapsGeoTiles.maxDate;
    }
    setMapsGeoTiles(newMapsGeoTiles);
    await updateTileConfig(newMapsGeoTiles);
  };

  const toggleDialog = ({ data = {}, type = '' } = {}) => setDialog({ isOpen: !dialog.isOpen, data, type });

  const getDialogType = () => {
    switch (dialog.type) {
      case 'create-map': {
        return <SmaMapCreateDialog actions={{ saveAnalyticMap, closeDialog }} />;
      }
      case 'geo-info': {
        return <ProcessGeoInfoDialog actions={{ closeDialog }}/>;
      }
      case 'photo':
        return <DialogExternalImage
          pictureData={dialog.data.pictureData}
          additionalClass='image-species-container'
        />;
      default:
        break;
    }
  };

  return (
    <Grid container>
      <TitleContainer maxWidth="xl" breadcrumbs={[ 'projects', { name: ucofl(props.name) } ]}>
        {ucofl(props.name)}
      </TitleContainer>
      <ContentContainer maxWidth="xl">
        <ActionsBox mt={2} mb={1} display="flex" alignItems="flex-end">
          <AccessControl action='project:upload-interest-area'>
            <Box flexGrow={1} display="flex" height="30px">
              <Button
                disabled={fetchStatus === LOADING}
                size="small"
                color="primary"
                startIcon={<AddBox />}
                variant="contained"
                onClick={openCreateMapDialog}
              >
                Agregar área de interés
              </Button>
            </Box>
          </AccessControl>
          {analyticMap.layers.length > 0 &&
            <AccessControl action='project:process-interest-area'>
              <Box display="flex" height="30px">
                <Button
                  disabled={fetchStatus === LOADING || geoInfo.inProgress}
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={processInterestArea}
                >
                  {`${geoInfo.inProgress ? 'Procesando área de interés...' : 'Procesar área de interés'}` }
                </Button>
              </Box>
            </AccessControl>
          }
        </ActionsBox>

        <Grid container spacing={1}>
          <Grid container item xs={12} direction="column">
            {analyticMap.layers.length > 0 &&
              <>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    {(geoInfo.hasData && !geoInfo.inProgress) &&
                      <Box display="flex">
                        <Box width="100%" display="flex">
                          <Grid container>
                            <Box width="100%" display="flex">
                              <Grid item xs={4}>
                                <Box mb={3}>
                                  <Typography variant="body2" required>Fecha sección izquierda</Typography>
                                  <DatePicker value={mapsGeoTiles?.leftDate} selected={mapsGeoTiles?.leftDate} autoOk
                                    TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                                    views={[ 'year', 'month' ]} minDate={mapsGeoTiles.minDate}
                                    maxDate={mapsGeoTiles.maxDate} format="MM-YYYY"
                                    onChange={changeTime('leftDate')}
                                    disabled={tileData.inProgress}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box ml={3} mb={3}>
                                  <Typography variant="body2" required>Fecha sección derecha</Typography>
                                  <DatePicker value={mapsGeoTiles?.rightDate} selected={mapsGeoTiles?.rightDate} autoOk
                                    TextFieldComponent={props => <TextField {...props} variant="outlined" size="small" />}
                                    views={[ 'year', 'month' ]} minDate={mapsGeoTiles.minDate}
                                    maxDate={mapsGeoTiles.maxDate} format="MM-YYYY"
                                    onChange={changeTime('rightDate')}
                                    disabled={tileData.inProgress}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box ml={3} mb={3}>
                                  <Typography variant="body2" required>Indicador</Typography>
                                  <AutocompleteWrapper
                                    disabled={tileData.inProgress}
                                    value={mapsGeoTiles?.tile || null}
                                    options={tileOptions}
                                    onChange={handleChange('tile')}
                                    getOptionSelected={(option, value) => (option.value === value.value || value.value === '')}
                                  />
                                </Box>
                              </Grid>
                            </Box>
                          </Grid>
                        </Box>
                      </Box>
                    }

                    <MapWrapper
                      id="interest-area-map"
                      className={classes.map}
                      options={{
                        ...mapOptions,
                        timeDimensionOptions: {
                          currentTime: analyticMap.dates[0],
                          times: analyticMap.dates,
                        },
                        timeDimensionControl: analyticMap.dates.length > 1,
                      }}
                      height={400}
                      layers={[
                        ...analyticMap.layers,
                        ...(analyticMap.occurrencesLayer ? [ analyticMap.occurrencesLayer ] : []),
                      ]}
                      legend={analyticMap.legend}
                      fitBoundsOnUpdate
                      comp={ tilesComp }
                    />
                    {(geoInfo.hasData && !geoInfo.inProgress) &&
                      <Box marginTop={2}>
                        <Grid item xs={12}>
                          <SmaGeoInfo geoInfo={geoInfo} />
                        </Grid>
                      </Box>
                    }
                  </Paper>
                </Grid>
              </>
            }
          </Grid>
          <Grid container item xs={6} direction="column">
            <Paper className={classes.paper}>
              { (auditableUnits.length > 0 || holders.length > 0 || rcas.length > 0) &&
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box className={classes.itemRootBorder} height="88px">
                        <ListIndicator
                          title="Unidades"
                          color="gray600"
                          list={getSelectedAuditableUnit(auditableUnits, selectedAuditableUnitId).map(au => au.name)}
                          isLoading={isLoading}
                          maxItems={3}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className={classes.itemRootBorder} height="88px">
                        <ListIndicator
                          title="Titulares"
                          list={holders}
                          isLoading={isLoading}
                          labelChipProps={{ className: classes.auditableUnitLabel }}
                          hideCount
                          maxItems={3}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <ListIndicator title="RCA's" list={rcas} color="primary" isLoading={isLoading} maxItems={5} />
                    </Grid>
                  </Grid>
                  <Box py={2}>
                    <Divider />
                  </Box>
                </>
              }

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box className={classes.itemRootBorder} height="88px">
                    <NumberIndicator
                      title="Planes de monitoreo"
                      value={indicators.monitoringCount}
                      isLoading={isLoading}
                      goTo={goToMonitoringPlans}
                      goToLabel="Ver todos"
                      hasRate={false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className={classes.itemRootBorder} height="100%">
                    <NumberIndicator
                      title="Campañas"
                      value={indicators.campaignsCount}
                      isLoading={isLoading}
                      goTo={goToCampaigns}
                      goToLabel="Ver todas"
                      hasRate={false}
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box height="100%">
                    <NumberIndicator
                      title="Estaciones replica"
                      value={indicators.replicaStationsCount}
                      isLoading={isLoading}
                      hasRate={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            <Box my={0.5} />
            {/* <AnalyticCard
              title="Unidades"
              {...(selectedAuditableUnit ? {
                ActionComponent: () =>
                  <Chip
                    color="secondary"
                    label={truncateWithEllipsis(selectedAuditableUnit.name + selectedAuditableUnit.name, 50)}
                    size="small"
                    onDelete={() => setSelectedAuditableUnitId(undefined)}
                  />,
              } : {})}
            >
              <Divider />
              {isLoading && <Box mt={0.5}><Skeleton animation="wave" variant="rect" height={heightVH(50)} /></Box>}
              <MapWrapper
                id="auditable-units-map"
                className={classes.map}
                options={mapOptions}
                height={isLoading ? 0 : heightVH(50)}
                layers={layers}
                mapRef={mapRef}
              />
            </AnalyticCard>
            <Box my={0.5} /> */}
            <AnalyticCard
              title="Lista de EECC"
              ActionComponent={
                () => <SpeciesTypeFilter speciesType={eeccTypeFilter} setSpeciesType={setEeccTypeFilter} showAllOption={false} />
              }
            >
              <Divider />
              {isLoading && <Box mt={0.5}><Skeleton animation="wave" variant="rect" height={100} /></Box>}
              <Box my={0.5} />
              {!isLoading && <EeccList eeccs={eeccs} eeccTypeFilter={eeccTypeFilter} actions={{ toggleDialog }} />}
            </AnalyticCard>
          </Grid>

          <Grid container item xs={6} direction="column">
            <AnalyticCard
              title={`Información de especies en año ${speciesIndicators.lastYear}`}
              ActionComponent={
                () => <SpeciesTypeFilter speciesType={speciesTypeFilter} setSpeciesType={setSpeciesTypeFilter} />
              }
            >
              <Divider />
              <Box my={1} />
              <Grid container spacing={2}>
                <Grid item xs>
                  <Box className={classes.itemRootBorder}>
                    <NumberIndicator
                      title="Ocurrencias"
                      value={speciesIndicators.occurrences?.lastValue}
                      total={speciesIndicators.occurrences?.total}
                      rate={speciesIndicators.occurrences?.rateValue}
                      isLoading={isLoading}
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box className={classes.itemRootBorder}>
                    <NumberIndicator
                      title="Riqueza"
                      value={speciesIndicators.species?.lastValue}
                      total={speciesIndicators.species?.total}
                      rate={speciesIndicators.species?.rateValue}
                      isLoading={isLoading}
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box className={classes.itemRootBorder}>
                    <NumberIndicator
                      title="EECC"
                      value={speciesIndicators.eecc?.lastValue}
                      total={speciesIndicators.eecc?.total}
                      rate={speciesIndicators.eecc?.rateValue}
                      isLoading={isLoading}
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box className={classes.itemRootBorder}>
                    <NumberIndicator
                      title="Nativo"
                      value={speciesIndicators.natives?.lastValue}
                      total={speciesIndicators.natives?.total}
                      rate={speciesIndicators.natives?.rateValue}
                      isLoading={isLoading}
                      size="small"
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <NumberIndicator
                    title="Endémica"
                    value={speciesIndicators.endemic?.lastValue}
                    total={speciesIndicators.endemic?.total}
                    rate={speciesIndicators.endemic?.rateValue}
                    isLoading={isLoading}
                    size="small"
                  />
                </Grid>

              </Grid>

            </AnalyticCard>
            <Box my={0.5} />
            <AnalyticCard
              title="Información de especies por año"
              ActionComponent={
                () => <SpeciesTypeFilter speciesType={speciesByYearTypeFilter} setSpeciesType={setSpeciesByYearTypeFilter} />
              }
            >
              <Divider />
              <Box my={1} />
              {isLoading ?
                <LineChartSkeleton />
                : <ChartWrapper type="line" options={{}} data={speciesChart} />
              }

            </AnalyticCard>
            <Box my={0.5} />
            <Grid container item spacing={1}>
              <Grid item xs={4}>
                <AnalyticCard title="Distribución de reinos">
                  <Divider />
                  <Box my={1} />
                  {isLoading ?
                    <PieChartSkeleton />
                    : <ChartWrapper type="pie" options={{}} data={speciesAllocationCharts.kingdom} />
                  }
                </AnalyticCard>
              </Grid>
              <Grid item xs={4}>
                <AnalyticCard title="Clases de flora">
                  <Divider />
                  <Box my={1} />
                  {isLoading ?
                    <PieChartSkeleton />
                    : <ChartWrapper type="pie" options={{}} data={speciesAllocationCharts.flora} />
                  }
                </AnalyticCard>
              </Grid>
              <Grid item xs={4}>
                <AnalyticCard title="Clases de fauna">
                  <Divider />
                  <Box my={1} />
                  {isLoading ?
                    <PieChartSkeleton />
                    : <ChartWrapper type="pie" options={{}} data={speciesAllocationCharts.fauna} />
                  }
                </AnalyticCard>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
        <Box my={2} />
      </ContentContainer>
      <DialogWrap maxWidth="lg" fullWidth onClose={closeDialog} open={dialog.isOpen}>
        {getDialogType()}
      </DialogWrap>
    </Grid>
  );
};

SmaSummaryContainer.propTypes = {
  name: PropTypes.string,
};


export {
  SmaSummaryContainer,
};