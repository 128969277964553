import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { Card } from '@material-ui/core';

import { TitleContainer, ContentContainer, MapWrapper, ImageShowWrapper } from 'src/components';
import { useRouteMatch } from 'react-router';
import { useCampaignApi } from 'src/services';
import { getMarkerIcon } from 'src/components/map/mapHelper';
//import { ReplicaStationPopup } from 'src/scenes/Project/scenes/Campaign/scenes/Monitoring/ReplicaStationPopup';
import themes from 'src/themes';
import { getBaseBreadcrumbs } from 'src/scenes/Project/scenes/Campaign/SmaCampaignHelper';


const render = (Component, element) => {
  ReactDOM.render(
    <ThemeProvider theme={themes['nviro']}>
      <Component />
    </ThemeProvider>,
    element,
  );
};

/*
const getLatLng = replicaStation => {
  const { centralLatitude, centralLongitude, startLatitude, startLongitude, endLatitude, endLongitude } = replicaStation;
  if (centralLatitude && centralLongitude) {
    return { lat: centralLatitude, lng: centralLongitude };
  } else if (startLatitude && startLongitude) {
    return { lat: startLatitude, lng: startLongitude };
  } else if (endLatitude && endLongitude) {
    return { lat: endLatitude, lng: endLongitude };
  } else {
    return undefined;
  }
};

const createMarker = replicaStation => {
  const coords = getLatLng(replicaStation);
  if (coords) {
    const marker = new L.Marker(coords, { icon: getMarkerIcon('ER', 'nviro') });
    marker.metaData = replicaStation;
    marker.bindPopup(getElement(() => <ReplicaStationPopup {...replicaStation} />));
    return marker;
  } else {
    return undefined;
  }
};
*/

const mapOptions = {
  center: [ -32.48431324745671, -71.25296320202341 ],
  zoomControl: true,
  dragging: true,
  zoom: 16,
  doubleClickZoom: false,
  attributionControl: false,
};

const useStyles = makeStyles(theme => ({
  map: {
    borderRadius: theme.shape.borderRadius,
  },
  mapContainer: {
    padding: theme.spacing(1),
    height: 'fit-content',
    position: 'relative',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  content: {
    maxWidth: '100%',
  },
  root: {
    margin: '0px -24px -10px -20px',
    width: '300px',
  },
}));


const SmaMonitoringContainer = ({ campaign }) => {

  const classes = useStyles();
  const match = useRouteMatch();
  const campaignApi = useCampaignApi();

  //const [ replicaStations, setReplicaStations ] = useState([]);
  const [ occurrences, setOcurrences ] = useState([]);
  const [ token, setToken ] = useState('');
  const [ layers, setLayers ] = useState([]);
  //const [ layers, setLayers ] = useState([]);

  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const { projectId, campaignId } = match.params;

  const { name: campaignName } = campaign;

  useEffect(() => {
    const fetchData = async () => {
      //const replicaStations = await campaignApi.getReplicaStations(campaignId);
      //setReplicaStations(replicaStations);
      const occurrences = await campaignApi.getOccurrences(campaignId);
      setOcurrences(occurrences);
      let token;
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        console.error(e);
        await loginWithRedirect();
        return;
      }
      setToken(token);
    };
    fetchData();
  // eslint-disable-next-line
  }, [ campaignId ]);

  const createOcurrencesMarker = (occurrence, token) => {
    const { recordLatitude, recordLongitude } = occurrence;
    if (recordLatitude && recordLongitude) {
      const coords = { lat: recordLatitude, lng: recordLongitude };
      const marker = new L.Marker(coords, { icon: getMarkerIcon('O', 'nviro') });
      marker.metaData = occurrence;
      const images = occurrence.images.map(img => ({
        label: `${occurrence.genus} ${occurrence.epithet}`,
        parameter: occurrence.parameter,
        value: occurrence.value,
        unit: occurrence.valueUnit,
        date: `${occurrence.eventDay}/${occurrence.eventMonth}/${occurrence.eventYear}`,
        time: occurrence.eventTime,
        sampledBy: occurrence.sampledBy,
        url: img,
      }));
      const div = L.DomUtil.create('div');
      render(() =>
        <Card elevation={0} className={classes.root}>
          <ImageShowWrapper images={images} token={token}/>
        </Card>, div);
      marker.bindPopup(div);
      return marker;
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (token) {
      const layers = occurrences.map(o => createOcurrencesMarker(o, token)).filter(o => o);
      setLayers(layers);
    }
  }, [ occurrences, token ]);

  const height75vh = document.documentElement.clientHeight * 0.75;

  return (
    <Grid container>
      <TitleContainer
        maxWidth="xl"
        breadcrumbs={[
          ...getBaseBreadcrumbs({ projectId }),
          { name: `Mapa de ${campaignName}` },
        ]}
      >
        Mapa
      </TitleContainer>
      <ContentContainer className={classes.content}>
        <Paper className={classes.mapContainer}>
          <MapWrapper
            id="replica-stations-map"
            className={classes.map}
            options={mapOptions}
            height={height75vh}
            layers={[ ...layers ]}
            fitBoundsOnUpdate
          />
        </Paper>
      </ContentContainer>
    </Grid>
  );
};

SmaMonitoringContainer.propTypes = {
  campaign: PropTypes.object,
};


export {
  SmaMonitoringContainer,
};