import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Box, IconButton, MobileStepper, Typography, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { CaptureImageWrapper } from 'src/components';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  img: {
    height: 150,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  stepper: {
    backgroundColor: theme.palette.common.white,
  },
  imgContainer: {
    width: '150px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ImageShowWrapper = ({ images, token }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [ activeImage, setActiveImage ] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => setActiveImage(prevActiveStep => prevActiveStep + 1);

  const handleBack = () => setActiveImage(prevActiveStep => prevActiveStep - 1);

  return (
    <Grid container width='300px'>
      { images[activeImage].url ?
        <Grid container item xs={6} direction="column">
          <Box className={classes.imgContainer}>
            <CaptureImageWrapper imageUrl={images[activeImage].url} token={token} />
          </Box>
        </Grid> :
        <Grid container item xs={3} direction="column"></Grid>
      }
      <Grid container item xs={6 } direction="column">
        <Box className={classes.root}>
          <Box mt={1} ml={0.1}>
            <Typography variant="body2" component="div">{images[activeImage].label}</Typography>
            <Typography variant="body2" component="div">
              {images[activeImage].parameter}: {images[activeImage].value} {images[activeImage].unit}
            </Typography>
            <Typography variant="body2" component="div">Fecha: {images[activeImage].date}</Typography>
            <Typography variant="body2" component="div">Hora: {images[activeImage].time}</Typography>
            <Typography variant="body2" component="div">Autor: {images[activeImage].sampledBy}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container item xs={12} direction="column">
        <MobileStepper
          classes={{ root: classes.stepper }}
          steps={maxSteps}
          variant="text"
          position="static"
          activeStep={activeImage}
          nextButton={
            <IconButton size="small" onClick={handleNext} disabled={activeImage === maxSteps - 1}>
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
          }
          backButton={
            <IconButton size="small" onClick={handleBack} disabled={activeImage === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
          }
        />
      </Grid>

    </Grid>
  );
};

ImageShowWrapper.propTypes = {
  images: PropTypes.array,
  token: PropTypes.string.isRequired,
};


export { ImageShowWrapper };