import React, { useEffect, useMemo, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import L from 'leaflet';
import config from 'src/config/local';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import { Help } from '@material-ui/icons';
import 'leaflet/dist/leaflet.css';
import 'leaflet-side-by-side';


import { LayersPlus, MapMarkerPlus } from 'src/utils/mdIcons';
import { getMarkerIcon } from 'src/components/map/mapHelper';


const useStyles = makeStyles(() => ({
  map: {
    position: 'relative',
    width: '100%',
    height: ({ height }) => `${height}px`,
  },
}));

const { satelliteStreetsBaseMap } = config;

const addTools = map => {
  const drawnItems = new L.FeatureGroup();
  map.addLayer(drawnItems);
  const drawControl = new L.Control.Draw({
    position: 'topleft',
    draw: {
      rectangle: false,
      circlemarker: false,
      polyline: false,
      polygon: true,
      circle: false,
      marker: {
        icon: getMarkerIcon(5),
        repeatMode: true,
      },
    },
  });
  map.addControl(drawControl);

  const iconStyle = { color: '#464646' };

  const kmlImportPointsToolButton = L.easyButton({
    id: 'import-points-btn',
    position: 'topleft',
    type: 'replace',
    states: [ {
      title: 'Importar puntos (KML/KMZ)',
      icon: renderToString(<MapMarkerPlus fontSize="small" style={iconStyle} />),
    } ],
  });
  kmlImportPointsToolButton.addTo(map);

  const kmlImportAreaToolButton = L.easyButton({
    id: 'import-area',
    position: 'topleft',
    type: 'replace',
    states: [ {
      title: 'Importar área de interés (KML/KMZ)',
      icon: renderToString(<LayersPlus fontSize="small" style={iconStyle} />),
    } ],
  });
  kmlImportAreaToolButton.addTo(map);

  const helpButton = L.easyButton({
    id: 'help-btn',
    position: 'topleft',
    type: 'replace',
    states: [ {
      title: 'Ayuda',
      icon: renderToString(<Help fontSize="small" style={iconStyle} />),
    } ],
  });
  helpButton.addTo(map);
};


const MapWrapper = props => {
  const { id = 'map', className, options, layers = [], legend, showTools = false,
    disableZoom = false, height = 100, events, mapRef: ref, fitBoundsOnUpdate = false, comp = {} } = props;

  const classes = useStyles({ height });
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const mapRef = useMemo(() => ref || {}, [ ref ]);

  const mainLayerRef = useRef(new L.FeatureGroup());
  const mainLayer = mainLayerRef.current;
  const sliderRef = useRef(null);
  const sliderLayersRef = useRef({ left: [], right: [] });
  const startFitBound = useRef(false);

  const defaultOptions = {
    center: [ -33.4679997, -70.7003513 ],
    zoom: 5,
    layers: [
      L.tileLayer(satelliteStreetsBaseMap, {}),
    ],
  };

  useEffect(() => {
    mapRef.current = L.map(id, { ...defaultOptions, ...options });
    const map = mapRef.current;

    if (showTools) {
      addTools(map);
    }

    if (events) {
      const eventsObj = events(map);
      Object.keys(eventsObj).forEach(key => map.on(key, () => eventsObj[key]()));
    }

    if (disableZoom && map.zoomControl) {
      map.zoomControl.disable();
    }

    return () => map.remove();
  }, []);  // eslint-disable-line


  useEffect(() => {
    const map = mapRef.current;
    if (sliderRef.current) {
      sliderRef.current.remove();
    }

    sliderLayersRef.current.left.forEach(layer => map.removeLayer(layer));
    sliderLayersRef.current.right.forEach(layer => map.removeLayer(layer));

    if (comp.display && comp.left && comp.right) {
      const left = [];
      if (comp.left.indicator) {
        left.push(comp.left.indicator);
      }
      const right = [];
      if (comp.right.indicator) {
        right.push(comp.right.indicator);
      }
      left.forEach(layer => layer.addTo(map));
      right.forEach(layer => layer.addTo(map));
      sliderLayersRef.current.left = left;
      sliderLayersRef.current.right = right;
      sliderRef.current = L.control.sideBySide(left, right).addTo(map);
    }

    // eslint-disable-next-line
  }, [ comp ]);

  useEffect(() => {
    const map = mapRef.current;
    mainLayer.clearLayers();
    if (layers.length > 0) {
      layers.forEach(layer => {
        mainLayer.addLayer(layer);
      });
    }

    if (legend) {
      legend.addTo(map);
    }

    if (fitBoundsOnUpdate && layers.length && !startFitBound.current) {
      const bounds = mainLayer.getBounds();
      map.fitBounds(bounds);
      startFitBound.current = true;
    }
    mainLayer.addTo(map);
  }, [ layers ]);

  useEffect(() => {
    if (isDownSm && mapRef.current.zoomControl) {
      mapRef.current.removeControl(mapRef.current.zoomControl);
    }
  }, [ mapRef, isDownSm ]);

  return (
    <div id={id} className={clsx(classes.map, className)} />
  );
};

MapWrapper.propTypes = {
  className: PropTypes.string,
  options: PropTypes.object,
  id: PropTypes.string,
  layers: PropTypes.array,
  legend: PropTypes.object,
  showTools: PropTypes.bool,
  disableZoom: PropTypes.bool,
  fitBoundsOnUpdate: PropTypes.bool,
  height: PropTypes.number,
  events: PropTypes.func,
  mapRef: PropTypes.object,
  comp: PropTypes.object,
};


export { MapWrapper };